// 信息列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      :color="formNameColor"
      icon="mdi-clipboard-text"
      :title="`${formName}（共${projectTotal}个）`"
      class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
    >
      <search
        ref="search"
        class="mt-5"
        :page="page"
        :is-form="isForm"
        :is-add="
          !(
            ($route.query.formId === tjFormId && !orgQX) ||
            ($route.query.formId === wqFormId && orgQX)
          )
        "
      />
      <v-simple-table>
        <thead>
          <tr :class="isXs ? 'titles' : ''">
            <th
              style="min-width:40px"
            >
              序号
            </th>
            <th style="min-width:140px">
              名称
            </th>
            <th
              v-if="
                isEdit &&
                  ($route.query.formId === wsFormId ||
                    $route.query.formId === tjFormId)
              "
              style="min-width:84px"
            >
              审核状态
            </th>
            <th
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
              style="min-width:84px"
            >
              项目状态
            </th>
            <!-- <th
              v-if="isHshow && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)"
              width="80"
            >
              办理状态
            </th> -->
            <th
              style="min-width:84px"
            >
              产业类别
            </th>
            <th
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
              style="min-width:60px"
            >
              项目审核亮灯
            </th>
            <th
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
              style="min-width:60px"
            >
              项目推进亮灯
            </th>
            <th
              style="min-width:84px"
            >
              创建时间
            </th>
            <th
              style="min-width:84px"
            >
              提报用户
            </th>
            <!-- <th
              v-if=" orgQX && $route.query.formId === tjFormId"
              width="100"
            >
              审核状态
            </th> -->
            <th
              style="min-width:150px"
              class="text-right"
            >
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
            :class="isXs ? 'names' : ''"
          >
            <td>{{ page * pageSize + index + 1 }}</td>
            <td>
              <span
                :class="
                  project.status === -5
                    ? 'projectNameLink projectLinkcolor'
                    : 'indigo--text projectNameLink'
                "
                @click="see(index)"
              >
                <span v-if="Number(project.hsnum)>1 && isHshow">

                  <span
                    v-if="orgValue ==='ROLE_TZCJCFZRSHYJ'"
                  >
                    <v-badge
                      v-if="project.data.tzcfzrHandle === '已办理'"
                      offset-y="-1"
                      color="green"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else-if="project.data.tzcfzrHandle==='待办理'"
                      offset-y="-1"
                      color="#FC9A0B"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else
                      offset-y="-1"
                      color="red"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                  </span>
                  <span
                    v-else-if="orgValue ==='ROLE_TZCJCNBYJ'"
                  >
                    <v-badge
                      v-if="project.data.tzcHandle === '已办理'"
                      offset-y="-1"
                      color="green"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else-if="!project.data.tzcHandle"
                      offset-y="-1"
                      color="#FC9A0B"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else
                      offset-y="-1"
                      color="red"
                      :content="project.hsnum"
                    >
                      {{ project.data.name }}
                    </v-badge>
                  </span>
                  <span
                    v-else
                  >
                    <v-badge
                      v-if="project.data.kfqHandle === '已办理'"
                      color="green"
                      :content="project.hsnum"
                      offset-y="-1"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else-if="project.data.kfqHandle==='待办理'"
                      color="#FC9A0B"
                      :content="project.hsnum"
                      offset-y="-1"
                    >
                      {{ project.data.name }}
                    </v-badge>
                    <v-badge
                      v-else
                      color="red"
                      :content="project.hsnum"
                      offset-y="-1"
                    >
                      {{ project.data.name }}
                    </v-badge>
                  </span>
                </span>
                <span v-else>{{ project.data.name }}</span>
              </span>
              <v-btn
                v-copy="project.data.name"
                icon
                small
                dark
                color="#ccc"
              >
                <v-icon x-small>
                  fa fa-clipboard
                </v-icon>
              </v-btn>
            </td>
            <td
              v-if="
                isEdit &&
                  ($route.query.formId === wsFormId ||
                    $route.query.formId === tjFormId)
              "
            >
              <!-- 个人项目审核状态 -->
              <v-btn
                v-if="getaStatus(project)"
                outlined
                small
                :color="getaStatus(project).color"
                :style="getaStatus(project).background"
              >
                {{ getaStatus(project).name }}
              </v-btn>
            </td>
            <td
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
            >
              <v-btn
                v-if="project.status === -5"
                outlined
                small
                color="red"
                style="background-color:#FFEDEC"
              >
                待完善
              </v-btn>
              <v-btn
                v-else
                outlined
                small
                :color="getprojectColor(project.data.projectStatus2, 'color')"
                :style="
                  'background-color:' +
                    getprojectColor(project.data.projectStatus2, 'background')
                "
              >
                {{
                  project.data.projectStatus2
                    ? project.data.projectStatus2
                    : '未推送'
                }}
              </v-btn>
              <!--
                :style="'background-color:'+(getprojectColor(project.data.projectStatus2)).background" -->
              <!-- <v-btn
                v-if="project.data.isPush==='是'"
                outlined
                small
                color="green"
                style="background-color:#DFFCF8"
              >
                {{ '已推送' }}
              </v-btn>
              <v-btn
                v-else-if="project.status === 10"
                outlined
                small
                color="#1E90FF"
                style="background-color:#F1F8FF"
              >
                {{ '待推送' }}
              </v-btn>
              <v-btn
                v-else-if="project.status === -5"
                outlined
                small
                color="red"
                style="background-color:#FFEDEC"
              >
                {{ '待完善' }}
              </v-btn>
              <v-btn
                v-else-if="project.status === 5"
                outlined
                small
                color="#FC9A0B"
                style="background-color:#FFF2DF"
              >
                {{ '待确认' }}
              </v-btn> -->
            </td>
            <!-- <td v-if="orgValue ==='ROLE_TZCJCNBYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)">
              <v-btn
                v-if="project.data.tzcHandle === '已办理'"
                outlined
                small
                style="background-color:#DFFCF8"
                color="green"
              >
                {{ '已办理' }}
              </v-btn>
              <v-btn
                v-else-if="!project.data.tzcHandle && project.status === 10"
                outlined
                small
                style="background-color:#FFF2DF"
                color="#FC9A0B"
              >
                {{ '待办理' }}
              </v-btn>
              <v-btn
                v-else-if="project.data.tzcHandle=== '待完善'"
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ '待完善' }}
              </v-btn>
              <v-btn
                v-else
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ "未办理" }}
              </v-btn>
            </td>
            <td v-if="orgValue ==='ROLE_KFQCNBYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)">
              <v-btn
                v-if="project.data.kfqHandle==='已办理'"
                outlined
                small
                style="background-color:#DFFCF8"
                color="green"
              >
                {{ project.data.kfqHandle }}
              </v-btn>
              <v-btn
                v-else-if="project.data.kfqHandle==='待办理' && project.status === 10"
                outlined
                small
                style="background-color:#FFF2DF"
                color="#FC9A0B"
              >
                {{ project.data.kfqHandle }}
              </v-btn>
              <v-btn
                v-else-if="project.data.kfqHandle==='待完善'"
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ project.data.kfqHandle }}
              </v-btn>
              <v-btn
                v-else
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ "未办理" }}
              </v-btn>
            </td>
            <td v-if="orgValue ==='ROLE_TZCJCFZRSHYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)">
              <v-btn
                v-if="project.data.tzcfzrHandle==='已办理'"
                outlined
                small
                style="background-color:#DFFCF8"
                color="green"
              >
                {{ project.data.tzcfzrHandle }}
              </v-btn>
              <v-btn
                v-else-if="project.data.tzcfzrHandle==='待办理' && project.status === 10"
                outlined
                small
                style="background-color:#FFF2DF"
                color="#FC9A0B"
              >
                {{ project.data.tzcfzrHandle }}
              </v-btn>
              <v-btn
                v-else-if="project.data.tzcfzrHandle==='待完善'"
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ project.data.tzcfzrHandle }}
              </v-btn>
              <v-btn
                v-else
                outlined
                small
                style="background-color:#FFEDEC"
                color="red"
              >
                {{ "未办理" }}
              </v-btn>
            </td> -->
            <td>{{ project.data.industry }}</td>
            <td
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
            >
              <v-icon :color="timeColor(project)">
                mdi-lightbulb
              </v-icon>
            </td>
            <td
              v-if="
                $route.query.formId === wsFormId ||
                  $route.query.formId === tjFormId
              "
            >
              <v-icon
                v-if="project.data.projectStatusLight"
                :color="timeColor2(project)"
              >
                mdi-lightbulb
              </v-icon>
            </td>
            <td>{{ project.createdAt | dateformat1 }}</td>
            <td>{{ project.nickname }}</td>
            <!-- <td v-if=" orgQX && project.formId === tjFormId">
              {{ project.status | stateToName }}
            </td> -->
            <td class="text-right">
              <v-tooltip
                v-if="!orgQX && $route.query.formId === wqFormId"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="px-2 ml-1 mt-1"
                    min-width="0"
                    small
                    v-bind="attrs"
                    color="#FC9A0B"
                    v-on="on"
                    @click="transfer(project)"
                  >
                    <v-icon
                      small
                      class="mr-2"
                    >
                      mdi-file-swap-outline
                    </v-icon>
                    转移
                  </v-btn>
                </template>
                <span>转移到“外商投资合作项目库”</span>
              </v-tooltip>
              <!--projectStatus2项目状态推送过就可以填写  -->
              <v-btn
                v-if="
                  ($route.query.formId === wsFormId ||
                    $route.query.formId === tjFormId) &&
                    project.data.projectStatus2
                "
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="#0D47A1"
                @click="getAdvance(project)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-file-sync
                </v-icon>
                项目推进情况
              </v-btn>
              <v-btn
                v-if="isAdmin|| isEdit || project.status !== 5"
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="blue mt-0"
                @click="edit(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <!-- <v-btn
                v-if="project.formId !== tjFormId && !orgQX"
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="blue mt-0"
                @click="edit(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn> -->
              <!-- <v-btn
                v-if="project.status === 5 && orgQX && project.formId === tjFormId"
                class="px-2 ml-2"
                min-width="0"
                small
                color="orange mt-0"
                @click="recall(project,index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-keyboard-return
                </v-icon>
                撤回
              </v-btn> -->
              <v-btn
      					v-permission="['ROLE_TZCJZX']"
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="red darken-1"
                @click="deleteMobelBtn(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn>
              <v-btn
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="success"
                @click="see(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
              <!-- <v-btn
                v-if="($route.query.formId === wsFormId || $route.query.formId === tjFormId) && isAdmin"
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="#0D47A1"
                @click="see2(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-arrow-top-right-thin
                </v-icon>
                推送
              </v-btn> -->
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="nowPage"
        :length="pageNum"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
    <div class="py-3" />
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <audioBox ref="audioBox" />
    <advance-report ref="AdvanceReport" />
  </v-container>
</template>
<script>
// import qs from 'qs'
  import userPhoto from '../../assets/user_photo.jpg';
  export default {
    components: {
      search: () => import('../../components/base/Search'),
      audioBox: () => import('../../components/base/Audio'),
      AdvanceReport: () => import('../report/AdvanceReport.vue'),
    },
    data: () => ({
      isForm: false,
      userId: '',
      userPhoto: userPhoto,
      overlay: false,
      searchProjectName: '',
      industryCategory: '',
      status: '',
      formId: '',
      uiInfo: {
        reserve: {
          color: '',
          text: '',
        },
        negotiation: {
          color: '',
          text: '',
        },
        signing: {
          color: '',
          text: '',
        },
        implementation: {
          color: '',
          text: '',
        },
      },
      nowType: '',
      formName: '信息列表',
      projectList: [], // 项目数据列表
      projectName: '',
      projectId: '',
      deleteMobel: false, // 删除确认框
      page: 0,
      nowPage: 1,
      pageNum: 0,
      pageSize: 20,
      pageLenght: 0,
      delete_project_success: false, // 成功删除项目
      formNameColor: 'blue darken-4',
      isAdmin: false,
      projectTotal: 0,
      orgQX: false,
      isAdd: true,
      // 外商
      wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      // 推介
      tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
      // 客户
      kuFormId: 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a',
      // 外商寻求
      wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
      orgValue: '',
      // 是不是市级审核
      isMarket: false,
      // 推介和外商的审核人员
      isEdit: false,
      isHshow: false,
      projectStatus2s: [],
      projectColor: [
        { color: '#999999', background: '#F8F8F8' },
        { color: '#08AEAB', background: '#D8FEFD' },
        { color: '#2196F3', background: '#D8EEFF' },
        { color: '#2196F6', background: '#D8EEF6' },
        { color: 'green', background: '#DFFCF8' },
        { color: '#FC9A0B', background: '#FFF2DF' },
        { color: 'red', background: '#FFEDEC' },
        { color: '#08AEAB', background: '#D8FEFD' },
        { color: '#2196F3', background: '#D8EEFF' },
        { color: '#2196F6', background: '#D8EEF6' },
        { color: 'green', background: '#DFFCF8' },
        { color: '#FC9A0B', background: '#FFF2DF' },
      ],
      // 会商意见表(共用)
      hssearchParame: {
        formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
        _from: 'public',
        page: 0,
      },
      roles: '',
      isXs: false,
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          this.getProjectList();
          this.isStatus();
          this.uploadUi();
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.roles = localStorage.roles;
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true;
      this.isEdit =
        localStorage.isEdit === 'true' || localStorage.isEdit === true;
      this.isHshow =
        localStorage.isHshow === 'true' || localStorage.isHshow === true;

      this.nowPage = this.$route.query.page
        ? Number(this.$route.query.page) + 1
        : 1;
      if (
        this.$route.query.isForm === 'true' ||
        this.$route.query.isForm === true
      ) {
        this.isForm = true;
      } else {
        this.isForm = false;
      }
      this.isAdmin =
        localStorage.isAdmin === 'true' || localStorage.isAdmin === true;
      this.userId = localStorage.userId;

      this.isMarket = localStorage.isMarket === 'true';
      // 批量转移填报用户
      // this.$axios.get('/reports/query', {
      //   params: {
      //     formId: 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a,bce0f632-42a8-4cf7-a30c-e5c6f00c6e62,d4514d84-27ea-49e2-a4d7-2defc4deca96,d530b595-f82b-4e50-9bbc-5561225999d4',
      //     _from: 'org',
      //     size: '10000',
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data.data.content)
      //     const resData = res.data.data.content
      //     resData.map((report) => {
      //       console.log('填报ID' + report.id)
      //       console.log('用户ID' + report.user.id)
      //       this.$axios.put('/reports/' + report.id + '/transfer', {
      //         message: '',
      //         userId: report.user.id,
      //       })
      //     })
      //   })

      // 项目进展状态
      this.projectStatus2s = [];
      var list = [
        { name: '未推送', code: '' },
        { name: '已推送', code: '已推送' },
      ];
      var List2;
      if (this.$route.query.formId === this.wsFormId) {
        List2 = await this.getSelectDictData(
          'ba99ae56-7c5a-4fc0-98f0-93ea65c88878',
        );
      } else if (this.$route.query.formId === this.tjFormId) {
        List2 = await this.getSelectDictData(
          'eccd42d9-a0e3-41b6-bb29-05142e0753d2',
        );
      }
      this.projectStatus2s = list.concat(List2);
    },
    async mounted () {
      await this.getMeInfo();
      this.uploadUi();
      this.isStatus();
      await this.getProjectList();
    },
    methods: {
      timeColor (item) {
        let isLight = 1
        for (let inx = 0; inx < item.processes.length; inx++) {
          // 审核人员处于审核中
          if (this.roles.includes(item.processes[inx].roleValue) && item.processes[inx].active === 1 && item.processes[inx].status === 0) {
            isLight = 1
            // 审核人员
          } else if (this.roles.includes(item.processes[inx].roleValue)) {
            isLight = 2
          }
        }
        if (isLight === 1) {
          if (item.data.projectLight === '绿灯') {
            return '#20c954'
          } else if (item.data.projectLight === '红灯') {
            return 'red'
          } else if (item.data.projectLight === '黄灯') {
            return '#FF9900'
          } else {
            return '#20c954'
          }
        } else {
          return '#20c954'
        }
      },
      timeColor2 (item) {
        if (item.data.projectStatusLight === '绿灯') {
          return '#20c954'
        } else if (item.data.projectStatusLight === '红灯') {
          return 'red'
        } else if (item.data.projectStatusLight === '黄灯') {
          return '#FF9900'
        } else {
          return '#20c954'
        }
      },
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = [];
        await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
          const resData = res.data.data;
          resData.map((val) => {
            select.push({
              code: val.code,
              name: val.name,
            });
          });
        });
        return select;
      },
      getprojectColor (projectStatus2, type) {
        var color;
        if (type === 'color') {
          color = this.projectColor[0].color;
        } else {
          color = this.projectColor[0].background;
        }
        for (let inx = 0; inx < this.projectStatus2s.length; inx++) {
          if (this.projectStatus2s[inx].code === projectStatus2) {
            if (type === 'color') {
              color = this.projectColor[inx].color;
            } else {
              color = this.projectColor[inx].background;
            }
          }
        }
        return color;
      },
      getaStatus (item) {
        const roles = localStorage.roles;
        const lists = [];
        // 翻转下，一个人有多个审核角色按最高审核角色来
        item.processes.map((val) => {
          lists.unshift(val);
        });

        const list = lists.filter((val) => {
          return roles.includes(val.roleValue);
        });

        if (list.length) {
          if (list[0].status === 10) {
            return {
              name: '已通过',
              color: 'green',
              background: 'background-color:#DFFCF8',
            };
          } else if (list[0].status === -5) {
            return {
              name: '已驳回',
              color: 'red',
              background: 'background-color:#FFEDEC',
            };
          } else if (list[0].status === 0 && list[0].active === 1) {
            return {
              name: '待审核',
              color: '#FC9A0B',
              background: 'background-color:#FFF2DF',
            };
          } else if (list[0].status === 0 && list[0].active !== 1) {
            return {
              name: '未审核',
              color: 'red',
              background: 'background-color:#FFEDEC',
            };
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      getAdvance (item) {
        this.$refs.AdvanceReport.open(item);
      },
      isStatus () {
        if (this.$route.query.status) {
          return this.$route.query.status;
        } else {
          return false;
        }
      },
      uploadUi () {
        const formId = this.$route.query.formId;
        if (formId) {
          const form = this.$axios.get(`/forms/${formId}`);
          form.then((res) => {
            this.formName = res.data.data.name;
          });
        } else {
          this.formName = '信息列表';
        }
      },
      getMeInfo () {
        const roleName = JSON.parse(JSON.stringify(localStorage.roles));
        // 投资促进处负责人
        if (roleName.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ';
        // 开发区处
        } else if (roleName.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ';
        // 投资促进处
        } else if (roleName.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ';
        // 投促中心
        } else if (roleName.includes('ROLE_TZCJZX')) {
          this.orgValue = 'ROLE_TZCJZX';
        } else {
          this.orgValue = '';
        }
        console.log('this.orgValue', this.orgValue);
      },
      async getProjectList () {
        // 获取项目列表
        const searchParame = JSON.parse(JSON.stringify(this.$route.query));
        delete searchParame.isForm;
        searchParame.page = this.$route.query.page ? this.$route.query.page : 0;
        const orgQX =
          localStorage.orgQX === 'true' || localStorage.orgQX === true;
        console.log('searchParame', searchParame);

        // searchParame.projectStatus = '已注册项目'
        const Timeout = setTimeout(() => {
          this.overlay = true;
        }, 200);
        await this.$axios
          .get('/reports/query', {
            params: searchParame, // 搜索参数对象
          })
          .then(async (response) => {
            const resData = response.data.data;
            this.projectList = resData.content;
            this.projectList.map(async (item) => {
              if (item.data.tags) {
                item.data.tags = item.data.tags.split(',');
              } else if (item.data.industry) {
                const industrys = await this.getdictionar(
                  '08be7c1a-f3c7-4ef3-aef6-a2f56eb82531',
                );
                industrys.map((val) => {
                  if (val.code === item.data.industry) {
                    item.data.industry = val.name;
                  }
                });
              }
              if (item.formId === this.tjFormId || item.formId === this.wsFormId) {
                this.$set(item, 'hsnum', await this.getOpinion(item))
              }
            });
            this.projectStatistics = resData.totalElements; // 项目总数统计数
            this.pageNum = resData.totalPages;
            this.projectTotal = resData.totalElements;
            if (!resData.totalPages > 0) {
              this.$swal({
                title: '未搜索到数据!',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
              });
            }
            clearTimeout(Timeout);
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error);
            this.overlay = false;
          });
      },

      async getOpinion (item) {
        const hssearchParame = this.hssearchParame
        hssearchParame.parentID = item.id
        const resp = await this.$axios.get('/reports/query', {
          params: hssearchParame,
        })
        const desserts = resp.data.data.content
        return desserts.length
      },
      async getdictionar (name) {
        const resp = await this.$axios.get(`/dictionaries/${name}`);
        return resp.data.data;
      },
      addProjcet () {
        this.$router.push({
          name: 'Report',
          params: {
            formId: this.$route.params.formId,
          },
        });
      },
      transfer (item) {
        this.$swal({
          title: '你确定将此项目转移到 “外商投资合作项目库” 吗？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.transferTo(item);
          }
        });
      },
      async transferTo (item) {
        const resp = await this.$axios.put(
          `/reports/${item.id}/transfer-to/${this.wsFormId}`,
        );
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '转移失败!',
            icon: 'error',
          });
        }
        this.$swal({
          title: '转移成功!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getProjectList();
      },
      deleteMobelBtn (index) {
        // 删除按钮弹出弹框
        this.$swal({
          title:
            this.$route.query.formId === 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a'
              ? '你确定要删除"' + this.projectList[index].name + '"客户吗？'
              : '你确定要删除"' + this.projectList[index].name + '"项目吗？',
          text: '项目删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(index);
          }
        });
        this.ProjectName = this.projectList[index].name;
        this.ProjectId = this.projectList[index].id;
      },
      deleteProject (index) {
        // 删除项目
        this.$axios
          .delete('/reports/' + this.projectList[index].id)
          .then((res) => {
            this.projectList.splice(index, 1);
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            });
          });
      },
      delSuccess () {
        this.$swal({
          title: '删除成功!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      },
      edit (index) {
        // 编辑项目
        this.$router.push({
          path: '/report',
          query: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        });
      },
      recall (val, index) {
        // 撤回项目
        this.$axios
          .put(`reports/${val.id}/recall`)
          .then((res) => {
            this.$swal({
              title: '撤回成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.go(0);
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              title: '撤回失败!',
              icon: 'error',
            });
          });
      },
      see (index) {
        // 查看项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        });
      },
      see2 (index) {
        // 推送项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
          query: {
            isdynamic: 'true',
          },
        });
      },
      pageChange (value) {
        // 换页按钮
        this.nowPage = value;
        this.page = value - 1;
        const searchParameObj = {
          ...this.$route.query,
          ...{ page: this.page, size: 20 },
        };
        this.$router.push({
          path: '/list',
          query: searchParameObj,
        });
      },
    },
  };
</script>
<style scoped>
.projectNameLink {
  cursor: pointer;
}
.projectLinkcolor {
  color: red;
}
.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
</style>
