var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"regular-tables","tag":"section","fluid":""}},[_c('base-material-card',{staticClass:"mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12",attrs:{"inline":"","color":_vm.formNameColor,"icon":"mdi-clipboard-text","title":`${_vm.formName}（共${_vm.projectTotal}个）`}},[_c('search',{ref:"search",staticClass:"mt-5",attrs:{"page":_vm.page,"is-form":_vm.isForm,"is-add":!(
          (_vm.$route.query.formId === _vm.tjFormId && !_vm.orgQX) ||
          (_vm.$route.query.formId === _vm.wqFormId && _vm.orgQX)
        )}}),_c('v-simple-table',[_c('thead',[_c('tr',{class:_vm.isXs ? 'titles' : ''},[_c('th',{staticStyle:{"min-width":"40px"}},[_vm._v(" 序号 ")]),_c('th',{staticStyle:{"min-width":"140px"}},[_vm._v(" 名称 ")]),(
              _vm.isEdit &&
                (_vm.$route.query.formId === _vm.wsFormId ||
                  _vm.$route.query.formId === _vm.tjFormId)
            )?_c('th',{staticStyle:{"min-width":"84px"}},[_vm._v(" 审核状态 ")]):_vm._e(),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('th',{staticStyle:{"min-width":"84px"}},[_vm._v(" 项目状态 ")]):_vm._e(),_c('th',{staticStyle:{"min-width":"84px"}},[_vm._v(" 产业类别 ")]),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('th',{staticStyle:{"min-width":"60px"}},[_vm._v(" 项目审核亮灯 ")]):_vm._e(),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('th',{staticStyle:{"min-width":"60px"}},[_vm._v(" 项目推进亮灯 ")]):_vm._e(),_c('th',{staticStyle:{"min-width":"84px"}},[_vm._v(" 创建时间 ")]),_c('th',{staticStyle:{"min-width":"84px"}},[_vm._v(" 提报用户 ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"150px"}},[_vm._v(" 操作 ")])])]),_c('tbody',_vm._l((_vm.projectList),function(project,index){return _c('tr',{key:index,class:_vm.isXs ? 'names' : ''},[_c('td',[_vm._v(_vm._s(_vm.page * _vm.pageSize + index + 1))]),_c('td',[_c('span',{class:project.status === -5
                  ? 'projectNameLink projectLinkcolor'
                  : 'indigo--text projectNameLink',on:{"click":function($event){return _vm.see(index)}}},[(Number(project.hsnum)>1 && _vm.isHshow)?_c('span',[(_vm.orgValue ==='ROLE_TZCJCFZRSHYJ')?_c('span',[(project.data.tzcfzrHandle === '已办理')?_c('v-badge',{attrs:{"offset-y":"-1","color":"green","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):(project.data.tzcfzrHandle==='待办理')?_c('v-badge',{attrs:{"offset-y":"-1","color":"#FC9A0B","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):_c('v-badge',{attrs:{"offset-y":"-1","color":"red","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")])],1):(_vm.orgValue ==='ROLE_TZCJCNBYJ')?_c('span',[(project.data.tzcHandle === '已办理')?_c('v-badge',{attrs:{"offset-y":"-1","color":"green","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):(!project.data.tzcHandle)?_c('v-badge',{attrs:{"offset-y":"-1","color":"#FC9A0B","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):_c('v-badge',{attrs:{"offset-y":"-1","color":"red","content":project.hsnum}},[_vm._v(" "+_vm._s(project.data.name)+" ")])],1):_c('span',[(project.data.kfqHandle === '已办理')?_c('v-badge',{attrs:{"color":"green","content":project.hsnum,"offset-y":"-1"}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):(project.data.kfqHandle==='待办理')?_c('v-badge',{attrs:{"color":"#FC9A0B","content":project.hsnum,"offset-y":"-1"}},[_vm._v(" "+_vm._s(project.data.name)+" ")]):_c('v-badge',{attrs:{"color":"red","content":project.hsnum,"offset-y":"-1"}},[_vm._v(" "+_vm._s(project.data.name)+" ")])],1)]):_c('span',[_vm._v(_vm._s(project.data.name))])]),_c('v-btn',{directives:[{name:"copy",rawName:"v-copy",value:(project.data.name),expression:"project.data.name"}],attrs:{"icon":"","small":"","dark":"","color":"#ccc"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa fa-clipboard ")])],1)],1),(
              _vm.isEdit &&
                (_vm.$route.query.formId === _vm.wsFormId ||
                  _vm.$route.query.formId === _vm.tjFormId)
            )?_c('td',[(_vm.getaStatus(project))?_c('v-btn',{style:(_vm.getaStatus(project).background),attrs:{"outlined":"","small":"","color":_vm.getaStatus(project).color}},[_vm._v(" "+_vm._s(_vm.getaStatus(project).name)+" ")]):_vm._e()],1):_vm._e(),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('td',[(project.status === -5)?_c('v-btn',{staticStyle:{"background-color":"#FFEDEC"},attrs:{"outlined":"","small":"","color":"red"}},[_vm._v(" 待完善 ")]):_c('v-btn',{style:('background-color:' +
                  _vm.getprojectColor(project.data.projectStatus2, 'background')),attrs:{"outlined":"","small":"","color":_vm.getprojectColor(project.data.projectStatus2, 'color')}},[_vm._v(" "+_vm._s(project.data.projectStatus2 ? project.data.projectStatus2 : '未推送')+" ")])],1):_vm._e(),_c('td',[_vm._v(_vm._s(project.data.industry))]),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('td',[_c('v-icon',{attrs:{"color":_vm.timeColor(project)}},[_vm._v(" mdi-lightbulb ")])],1):_vm._e(),(
              _vm.$route.query.formId === _vm.wsFormId ||
                _vm.$route.query.formId === _vm.tjFormId
            )?_c('td',[(project.data.projectStatusLight)?_c('v-icon',{attrs:{"color":_vm.timeColor2(project)}},[_vm._v(" mdi-lightbulb ")]):_vm._e()],1):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("dateformat1")(project.createdAt)))]),_c('td',[_vm._v(_vm._s(project.nickname))]),_c('td',{staticClass:"text-right"},[(!_vm.orgQX && _vm.$route.query.formId === _vm.wqFormId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2 ml-1 mt-1",attrs:{"min-width":"0","small":"","color":"#FC9A0B"},on:{"click":function($event){return _vm.transfer(project)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-swap-outline ")]),_vm._v(" 转移 ")],1)]}}],null,true)},[_c('span',[_vm._v("转移到“外商投资合作项目库”")])]):_vm._e(),(
                (_vm.$route.query.formId === _vm.wsFormId ||
                  _vm.$route.query.formId === _vm.tjFormId) &&
                  project.data.projectStatus2
              )?_c('v-btn',{staticClass:"px-2 ml-1 mt-1",attrs:{"min-width":"0","small":"","color":"#0D47A1"},on:{"click":function($event){return _vm.getAdvance(project)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-sync ")]),_vm._v(" 项目推进情况 ")],1):_vm._e(),(_vm.isAdmin|| _vm.isEdit || project.status !== 5)?_c('v-btn',{staticClass:"px-2 ml-1 mt-1",attrs:{"min-width":"0","small":"","color":"blue mt-0"},on:{"click":function($event){return _vm.edit(index)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" 编辑 ")],1):_vm._e(),_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:(['ROLE_TZCJZX']),expression:"['ROLE_TZCJZX']"}],staticClass:"px-2 ml-1 mt-1",attrs:{"min-width":"0","small":"","color":"red darken-1"},on:{"click":function($event){return _vm.deleteMobelBtn(index)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" fa4 fa-trash-o ")]),_vm._v(" 删除 ")],1),_c('v-btn',{staticClass:"px-2 ml-1 mt-1",attrs:{"min-width":"0","small":"","color":"success"},on:{"click":function($event){return _vm.see(index)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" fa4 fa-eye ")]),_vm._v(" 查看 ")],1)],1)])}),0)]),_c('v-pagination',{attrs:{"length":_vm.pageNum,"total-visible":"10","color":"indigo darken-4","prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":_vm.pageChange},model:{value:(_vm.nowPage),callback:function ($$v) {_vm.nowPage=$$v},expression:"nowPage"}})],1),_c('div',{staticClass:"py-3"}),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('audioBox',{ref:"audioBox"}),_c('advance-report',{ref:"AdvanceReport"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }